<template>
  <router-view />
  <Loader />
  <ModalsContainer />
</template>
<script>
import { ModalsContainer } from 'vue-final-modal';
import Loader from './components/Loader';

export default {
  name: 'App',
  components: {
    ModalsContainer,
    Loader,
  },
};
</script>
<style lang="scss">
@import '~toastify-js/src/toastify.css';
h1 {
  font-size: 1.6em;
}

.ql-snow .ql-editor h1 {
  font-size: 1.6em;
}
.ql-snow .ql-editor h2 {
  font-size: 1.3em;
}
.ql-snow .ql-editor h3 {
  font-size: 1.1em;
}
.ql-align-right {
  text-align: right;
}

.ql-align-center {
  text-align: center;
}
.ql-container {
  font-family: 'Inter', sans-serif;
}
#ql-editor .ql-container {
  font-size: 18px;
}

.df {
  display: flex;
}
#app {
  background: #f1f1f1;
}

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.cp {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.input-style {
  box-sizing: content-box;
  display: flex;
  padding: 8px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  background: #fff;
  font-size: 16px;
  height: 22px;
  caret-color: #5729e9;
}

.textarea {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  background: #fff;
  height: 22px;
  caret-color: #5729e9;
  resize: none;
}

.input-style:disabled {
  background: #f3f4f6;
  color: #9ca3af;
}

.input-style::placeholder {
  color: rgba(175, 176, 186, 1);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.input-style:focus,
textarea {
  outline: none;
}

.input-style:focus-visible {
  border: 1px solid #ccbafe;
}

.input_valid_error {
  border: 1px solid #f43f5e;
}

.input_valid_error.input-style:focus-visible {
  border: 1px solid #f43f5e;
}

.label_valid {
  color: #dd1760;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-top: 4px;
}
.label-style {
  color: rgba(175, 176, 186, 1);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.toastify {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  overflow: hidden;

  .toast-close {
    color: #ffffff00;
    position: relative;
    background: none;

    &::after {
      content: url('./assets/icons/close_toast_icon.svg');
      position: absolute;
      right: 4px;
    }
  }
}

.btn-black {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 15px;
  background: #0e0f33;
  border: 0 solid;
  cursor: pointer;
  text-decoration: none;

  color: #fff;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.btn-white {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 15px;
  border: 0 solid;
  cursor: pointer;
  text-decoration: none;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(14, 15, 51, 0.03);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.btn-red {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 15px;
  background: #f43f5e;
  border: 0 solid;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  color: #fff;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
#image-cropper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  .vue-rectangle-stencil__bounding-box {
    border-radius: 24px;
    overflow: hidden;
  }

  .vue-advanced-cropper__background,
  .vue-advanced-cropper__image-wrapper,
  .vue-advanced-cropper__foreground {
    display: none;
  }
}
@font-face {
  font-family: 'Satoshi';
  src: local('Satoshi'),
    url(./assets/fonts/Satoshi-Variable.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./assets/fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Notable-Regular';
  src: local('Notable-Regular'),
    url('./assets/fonts/Notable-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'KumbhSans';
  src: local('KumbhSans'),
    url('./assets/fonts/kumbh-sans/KumbhSans-VariableFont_YOPQ,wght.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Fraunces';
  src: local('Fraunces'),
    url('./assets/fonts/Fraunces-VariableFont_SOFT,WONK,opsz,wght.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter.ttf) format('truetype');
}

@font-face {
  font-family: 'Forum-Regular';
  src: local('Forum-Regular'),
    url(./assets/fonts/Forum-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans'),
    url(./assets/fonts/work_sans/WorkSans-VariableFont_wght.ttf)
      format('truetype');
}

@font-face {
  font-family: 'DM_Sans_Italic';
  src: local('DM_Sans_Italic'),
    url('./assets/fonts/dm_sans/DMSans-Italic-VariableFont_opsz,wght.ttf')
      format('truetype');
}

@font-face {
  font-family: 'DM_Sans';
  src: local('DM_Sans'),
    url('./assets/fonts/dm_sans/DMSans-VariableFont_opsz,wght.ttf')
      format('truetype');
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: local('PlayfairDisplay'),
    url('./assets/fonts/dm_sans/DMSans-VariableFont_opsz,wght.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Epilogue';
  src: local('Epilogue'),
    url('./assets/fonts/epilogue/Epilogue-VariableFont_wght.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url('./assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: RobotoThin;
  src: local('RobotoThin'),
    url('./assets/fonts/roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoLight';
  src: local('RobotoLight'),
    url('./assets/fonts/roboto/Roboto-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'RobotoBold';
  src: local('RobotoBold'),
  url('./assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'RobotoCondensed';
  src: local('RobotoCondensed'),
    url('./assets/fonts/roboto_condensed/RobotoCondensed-VariableFont_wght.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url('./assets/fonts/montserrat/Montserrat-VariableFont_wght.ttf')
      format('truetype');
}

@font-face {
  font-family: 'PoiretOne';
  src: local('PoiretOne'),
    url('./assets/fonts/poiret_one/PoiretOne-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'BlackHanSans';
  src: local('BlackHanSans'),
    url('./assets/fonts/black_han_sans/BlackHanSans-Regular.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Lato-Light';
  src: local('Lato-Light'),
    url('./assets/fonts/lato/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-LightItalic';
  src: local('Lato-LightItalic'),
    url('./assets/fonts/lato/Lato-LightItalic.ttf') format('truetype');
}

@media (max-width: 992px) {
  .btn-black {
    font-size: 14px;
    line-height: 20px;
  }
  .input-style::placeholder {
    font-size: 14px;
    line-height: 20px;
  }
}
.btn-disabled {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 15px;
  border: 0 solid;
  cursor: pointer;
  text-decoration: none;
  color: #9ca3af;
  background: #d1d5db;
  box-shadow: 0px 2px 8px 0px rgba(14, 15, 51, 0.03);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
</style>
